@charset "UTF-8";
/** FORM ELEMENTS */
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:focus:-webkit-autofill,
input:-webkit-autofill:focus,
input:focus:-webkit-autofill {
  -moz-box-shadow: 0px 0px 0px 1000px white inset !important;
  -webkit-box-shadow: 0px 0px 0px 1000px white inset !important;
  box-shadow: 0px 0px 0px 1000px white inset !important;
}

.validation-error-label,
.validation-valid-label {
  padding-left: 5px;
  margin-top: 5px;
}
.validation-error-label:before,
.validation-valid-label:before {
  content: "";
}

.form-control, output {
  font-size: 15px;
}

.state-success .form-control.bordered {
  background-color: #F0FEE9;
  border: 1px solid #A5D491;
}
.state-success .form-control.bordered:focus {
  border: 1px solid #4ea5e0;
}
.state-success .form-control-feedback, .state-success .text-muted {
  color: #A5D491;
}

.has-feedback label ~ .form-control-feedback {
  top: 28px;
}

.state-error .form-control.bordered {
  background-color: #FEE9EA;
  border: 1px solid #DE888A;
}
.state-error .form-control.bordered:focus {
  outline-color: #DE888A;
  border: 1px solid #DE888A;
}
.state-error .form-control.bordered:hover {
  border-color: #DE888A;
}
.state-error .form-control.bordered:hover[disabled] {
  border-color: #ddd;
}
.state-error .form-control-feedback, .state-error .text-muted {
  color: #DE888A;
}

.form-control.bordered {
  border: 1px solid #DDD;
  -moz-box-shadow: inset 0 1px 3px -1px #ddd;
  -webkit-box-shadow: inset 0 1px 3px -1px #ddd;
  box-shadow: inset 0 1px 3px -1px #ddd;
  color: #626262;
  outline: 1px solid transparent;
  -moz-transition: outline-color 0.5s ease;
  -o-transition: outline-color 0.5s ease;
  -webkit-transition: outline-color 0.5s ease;
  transition: outline-color 0.5s ease;
  padding-left: 10px;
  padding-right: 10px !important;
  background-color: white;
}
.form-control.bordered[disabled] {
  background-color: #fafafa;
  border: 1px solid #ddd;
}
.form-control.bordered.mini {
  font-size: 13px;
  height: 30px;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.has-feedback-left .form-control.bordered, .has-feedback-left.input-group .form-control.bordered {
  padding-left: 32px;
}
.form-control.bordered:hover:not([disabled]) {
  border-color: #4ea5e0;
}
.form-control.bordered:focus {
  outline-color: #5d9cec;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.form-control.bordered.bootstrap-select.mini .btn-default {
  padding: 3px 24px 3px 0;
}
.form-control.bordered.bootstrap-select .btn-default {
  border-bottom-color: transparent;
}
.form-control.bordered.bootstrap-select.btn-group.open > .btn-default.dropdown-toggle {
  border-bottom-color: transparent;
}
.form-control.bordered.bootstrap-select .btn-default.active, .form-control.bordered.bootstrap-select .btn-default.focus, .form-control.bordered.bootstrap-select .btn-default:active, .form-control.bordered.bootstrap-select .btn-default:focus {
  box-shadow: none;
}

.checkbox {
  margin-top: 0;
  margin-bottom: 0;
}

/** FORM ELEMENTS END */
body, .tooltip, .popover {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.page-title {
  padding: 20px 36px 20px 0;
}

.navbar-nav > li > a {
  line-height: 21px;
}

.btn-mini {
  padding: 5px 10px;
  font-size: 12px;
}
.btn-mini .glyphicon {
  font-size: 11px;
}

.btn.heading-btn.btn-labeled {
  line-height: 1.4;
}

@media (min-width: 769px) {
  .sidebar-fixed.affix {
    top: 70px;
  }
}
/** Sweet alert */
.sweet-alert {
  padding: 17px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.sweet-alert h2 {
  color: #575757;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
  margin: 25px 0;
  padding: 0;
  line-height: 40px;
  display: block;
}
.sweet-alert p {
  color: #797979;
  font-size: 16px;
  text-align: center;
  font-weight: 300;
  position: relative;
  text-align: inherit;
  float: none;
  margin: 0;
  padding: 0;
  line-height: normal;
}
.sweet-alert button {
  padding: 10px 32px;
  margin: 26px 5px 0 5px;
  font-size: 17px;
  font-weight: 500;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

textarea {
  resize: none;
}

.panel-title i {
  font-size: 16px;
  margin-top: -2px;
  margin-right: 4px;
}

form .modal-title {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}
form .modal-title i {
  font-size: 16px;
  margin-top: -2px;
  margin-right: 4px;
}

.sidebar .tabbable .nav-tabs.nav-tabs-top > li.active > a, .sidebar .tabbable .nav-tabs.nav-tabs-top > li.active > a:hover,
.sidebar .tabbable .nav-tabs.nav-tabs-top > li.active > a:focus {
  background-color: white;
}
.sidebar .tabbable .nav-tabs {
  border-bottom: none;
}

.datetimepicker {
  padding: 16px;
}
.datetimepicker th.switch {
  font-weight: bold;
  font-size: larger;
}
.datetimepicker th.prev span {
  font-family: "icomoon" !important;
}
.datetimepicker th.prev span:before {
  content: "" !important;
}
.datetimepicker th.next span {
  font-family: "icomoon" !important;
}
.datetimepicker th.next span:before {
  content: "" !important;
}
.datetimepicker .datetimepicker-hours span, .datetimepicker .datetimepicker-minutes span {
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
}
.datetimepicker table tr tr.active:hover, .datetimepicker table tr tr.active:hover:hover, .datetimepicker table tr tr.active.disabled:hover, .datetimepicker table tr tr.active.disabled:hover:hover, .datetimepicker table tr tr.active:active, .datetimepicker table tr tr.active:hover:active, .datetimepicker table tr tr.active.disabled:active, .datetimepicker table tr tr.active.disabled:hover:active, .datetimepicker table tr tr.active.active, .datetimepicker table tr tr.active:hover.active, .datetimepicker table tr tr.active.disabled.active, .datetimepicker table tr tr.active.disabled:hover.active, .datetimepicker table tr tr.active.disabled, .datetimepicker table tr tr.active:hover.disabled, .datetimepicker table tr tr.active.disabled.disabled, .datetimepicker table tr tr.active.disabled:hover.disabled, .datetimepicker table tr tr.active[disabled], .datetimepicker table tr tr.active:hover[disabled], .datetimepicker table tr tr.active.disabled[disabled], .datetimepicker table tr tr.active.disabled:hover[disabled] {
  background-color: #2196F3;
  border-color: #2196F3;
}
.datetimepicker table tr td.active:hover, .datetimepicker table tr td.active:hover:hover, .datetimepicker table tr td.active.disabled:hover, .datetimepicker table tr td.active.disabled:hover:hover, .datetimepicker table tr td.active:active, .datetimepicker table tr td.active:hover:active, .datetimepicker table tr td.active.disabled:active, .datetimepicker table tr td.active.disabled:hover:active, .datetimepicker table tr td.active.active, .datetimepicker table tr td.active:hover.active, .datetimepicker table tr td.active.disabled.active, .datetimepicker table tr td.active.disabled:hover.active, .datetimepicker table tr td.active.disabled, .datetimepicker table tr td.active:hover.disabled, .datetimepicker table tr td.active.disabled.disabled, .datetimepicker table tr td.active.disabled:hover.disabled, .datetimepicker table tr td.active[disabled], .datetimepicker table tr td.active:hover[disabled], .datetimepicker table tr td.active.disabled[disabled], .datetimepicker table tr td.active.disabled:hover[disabled] {
  background-color: #2196F3;
  border-color: #2196F3;
}
.datetimepicker table tr td span.active:hover, .datetimepicker table tr td span.active:hover:hover, .datetimepicker table tr td span.active.disabled:hover, .datetimepicker table tr td span.active.disabled:hover:hover, .datetimepicker table tr td span.active:active, .datetimepicker table tr td span.active:hover:active, .datetimepicker table tr td span.active.disabled:active, .datetimepicker table tr td span.active.disabled:hover:active, .datetimepicker table tr td span.active.active, .datetimepicker table tr td span.active:hover.active, .datetimepicker table tr td span.active.disabled.active, .datetimepicker table tr td span.active.disabled:hover.active, .datetimepicker table tr td span.active.disabled, .datetimepicker table tr td span.active:hover.disabled, .datetimepicker table tr td span.active.disabled.disabled, .datetimepicker table tr td span.active.disabled:hover.disabled, .datetimepicker table tr td span.active[disabled], .datetimepicker table tr td span.active:hover[disabled], .datetimepicker table tr td span.active.disabled[disabled], .datetimepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #2196F3;
  border-color: #2196F3;
}
.datetimepicker .table-condensed > tbody > tr > td, .datetimepicker .table-condensed > tbody > tr > th, .datetimepicker .table-condensed > tfoot > tr > td, .datetimepicker .table-condensed > tfoot > tr > th, .datetimepicker .table-condensed > thead > tr > td, .datetimepicker .table-condensed > thead > tr > th {
  padding: 7px 8px;
}

.checkbox-switchery {
  margin-bottom: 0;
}
.checkbox-switchery label {
  height: 24px;
}

.switchery-lg.checkbox-switchery .switchery {
  margin-top: 0;
}
.switchery-lg .switchery {
  height: 34px;
  width: 80px;
}
.switchery-lg .switchery > small {
  height: 34px;
  width: 34px;
}
